import React from "react";
import "../App.css";

export default function ScrollToTop() {
  const [scrollPosition, setScrollPosition] = React.useState(window.screenY);

  React.useEffect(() => {
    window.onscroll = () => {
      setScrollPosition(window.scrollY);
    };
  }, []);

  if (scrollPosition > 50) {
    return (
      <div
        style={
          window.innerWidth <= 600
            ? {
                position: "fixed",
                width: 40,
                height: 40,
                padding: 0,
                right: 25,
                bottom: 50,
              }
            : {}
        }
        id="scrollToTop"
        onClick={() => {
          console.log("CLick");
          window.scroll(0, 0);
        }}
      >
        &uarr;
      </div>
    );
  } else {
    return <></>;
  }
}
