import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import sg2 from "../../assets/media/images/sg-2.png";
import sg3 from "../../assets/media/images/sg-3.png";
import sg4 from "../../assets/media/images/sg-4.png";
import sg1 from "../../assets/media/images/sg-1.png";
import ArtistsImg from "../../assets/media/images/artists-2.png";
import Wall1 from "../../assets/media/images/wall1_green - menu.png";
import Wall2 from "../../assets/media/images/wall2_green - menu_small.png";

export const Artists = () => {
  return (
    <div className="behind artists pt-80 pb-40" id="artists">
      <Container>
        <Row>
          <Col md={6} className="desktop-only">
            <div className="hero__content">
              <img
                style={{ width: "20%" }}
                className="mg-b-40 artist-title-img"
                src={Wall1}
                alt=""
              />
              <div className="hero__para mg-t-40">
                <p>
                  To celebrate this unique blend of phygital art, fashion &
                  culture, ZOOW24 & the legendary writer TRANE have come
                  together to bless the headquarters of the Olmetex factory
                  located in the Italian province of Como with a unique mural
                  showcasing the main character of the Drip-Lab collection, Kult
                  - the gluttonous Pizza Monster.
                </p>
                <p>
                  In addition the mural features an homage to the late, great
                  Virgil Abloh as well as hidden Easter eggs that refer to
                  certain graffiti crews, the pandemic, web3 vernacular so so
                  much more.
                </p>
              </div>
              <div className="behind__logo mg-t-40">
                <img src={sg4} alt="" />
                <img src={sg3} alt="" />
              </div>
            </div>
          </Col>
          <Col md={6} className="desktop-only">
            <div className="hero__content">
              <img
                style={{ width: "20%" }}
                className="mg-b-40 artist-title-img"
                src={Wall2}
                alt=""
              />
              <div className="hero__para mg-t-40">
                <p>
                  As if one giant wall wasn’t enough, we had the honour of
                  having certified like Shiche & Shek throw up another mural as
                  a reference to eternal wisdom represented by the owl as the
                  centrepiece.
                </p>
                <p>
                  Apart from making this an amazing PR stunt, the Wall
                  represents another physical aspect of the Drip-Lab collection,
                  as the mural by ZOOW & TRANE is completely detachable and
                  available for assembly in any urban museum or art gallery, and
                  will be airdropped in digital form to all holders so that they
                  can finally own a piece of the streets by the greatest to ever
                  do it.
                </p>
              </div>

              <div className="behind__logo mg-t-40">
                <img src={sg1} alt="" />
                <img src={sg2} alt="" />
              </div>
            </div>
          </Col>
          <Col md={6} className="mobile-only">
            <div className="hero__content">
              <img className="mg-b-40 artist-title-img" src={Wall1} alt="" />
              <div className="hero__para mg-t-40">
                <p>
                  To celebrate this unique blend of phygital art, fashion &
                  culture, ZOOW24 & the legendary writer TRANE have come
                  together to bless the headquarters of the Olmetex factory
                  located in the Italian province of Como with a unique mural
                  showcasing the main character of the Drip-Lab collection, Kult
                  - the gluttonous Pizza Monster.
                </p>
                <p>
                  In addition the mural features an homage to the late, great
                  Virgil Abloh as well as hidden Easter eggs that refer to
                  certain graffiti crews, the pandemic, web3 vernacular so so
                  much more.
                </p>
              </div>

              <div className="behind__logo mg-t-40">
                <img src={sg4} alt="" />
                <img src={sg3} alt="" />
              </div>
            </div>
          </Col>
          <Col md={6} className="mobile-only">
            <div className="hero__content">
              <img className="mg-b-40 artist-title-img" src={Wall2} alt="" />
              <div className="hero__para mg-t-40">
                <p>
                  As if one giant wall wasn’t enough, we had the honour of
                  having certified like Shiche & Shek throw up another mural as
                  a reference to eternal wisdom represented by the owl as the
                  centrepiece.
                </p>
                <p>
                  Apart from making this an amazing PR stunt, the Wall
                  represents another physical aspect of the Drip-Lab collection,
                  as the mural by ZOOW & TRANE is completely detachable and
                  available for assembly in any urban museum or art gallery, and
                  will be airdropped in digital form to all holders so that they
                  can finally own a piece of the streets by the greatest to ever
                  do it.
                </p>
              </div>

              <div className="behind__logo mg-t-40">
                <img src={sg1} alt="" />
                <img src={sg2} alt="" />
              </div>
            </div>
          </Col>
        </Row>

        {/* desktop only */}
        <Row className="desktop-only">
          <Col
            md={12}
            className="desktop-only"
            style={{ paddingTop: "100px", paddingBottom: "70px" }}
          >
            <div className="behind__videoplayer">
              <ReactPlayer
                url="https://vimeo.com/843920388"
                controls={true}
                width="100%"
                height="720px"
              />
            </div>
          </Col>
          {/* <Col md={1} className="desktop-only"></Col> */}
          <Col md={12} className="desktop-only">
            <div className="hero__content">
              {/*  <img className="mg-b-40 artist-title-img" src={ArtistsImg} alt=""/> */}
              <h1
                style={{
                  color: "#AEFF0A",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "3rem",
                  lineHeight: "78px",
                  lineHeight: "100%",
                  textAlign: "justify",
                }}
              >
                The Artist
              </h1>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "2rem",
                  lineHeight: "39px",
                  lineHeight: "100%",
                  textAlign: "justify",
                }}
              >
                Zoow24
              </h2>
              <div className="hero__para mg-t-40">
                <p>
                  As a graffiti writer turned visual artist, ZOOW24 (
                  <a href="https://twitter.com/zoow24">@zoow24</a>) has a
                  penchant for bringing vivid thoughts to reality. This master
                  of NEWOLDSCHOOL and graffiti 3.0 pioneer has been out in the
                  trenches with trailblazers of street art, graphic design, and
                  collectibles scenes for 10+ years.
                </p>
                <p>
                  From bombing trains with Trane + graffiti OGs like Shiche &
                  Shek, to headlining museum showcases in Germany, to
                  collaborative graphic design campaigns for brands like Moncler
                  & Balenciaga, and having custom clothing drops with OFF-White
                  & BadDeal, ZOOW24 is deeply entrenched in an exclusive,
                  gate-kept street-culture society.
                </p>
                <p>
                  He now, thanks to phygital NFTs, wants to give you a chance to
                  get involved in multiple layers of that culture, by immersing
                  yourself in the Drip-Lab experiment. Beyond his vast
                  professional accomplishments and street credibility, ZOOW is
                  an extremely passionate collector, always on the lookout for
                  vintage & rare clothing, sneakers, vinyl toys, memorabilia,
                  games, and art.
                </p>
              </div>

              <div className="behind__logo mg-t-40">
                {/* <img src={sg4} alt="" />
                <img src={sg3} alt="" />
                <img src={sg1} alt="" />
                <img src={sg2} alt="" /> */}
              </div>
            </div>
          </Col>

          <Col md={12} className="desktop-only">
            <div className="behind__videoplayer">
              <ReactPlayer
                url="https://vimeo.com/843922624"
                controls={true}
                width="100%"
                height="720px"
              />
            </div>
          </Col>
        </Row>
        {/* desktop only */}

        {/* mobile only */}
        <Row className="mobile-only">
          <Col md={6} className="mobile-only">
            <div className="behind__videoplayer">
              <ReactPlayer
                url="https://vimeo.com/843920388"
                controls={true}
                width="100%"
                height="850px"
              />
            </div>
          </Col>
          <Col md={6} className="mobile-only">
            <div className="hero__content">
              <h1
                style={{
                  color: "#AEFF0A",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "700",
                  fontSize: "3rem",
                  lineHeight: "78px",
                  lineHeight: "100%",
                  textAlign: "justify",
                }}
              >
                The Artist
              </h1>
              <h2
                style={{
                  color: "#FFFFFF",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "2rem",
                  lineHeight: "39px",
                  lineHeight: "100%",
                  textAlign: "justify",
                }}
              >
                Zoow24
              </h2>
              {/* <img className="mg-b-40 artist-title-img" src={ArtistsImg} alt=""/> */}
              <div className="hero__para mg-t-30">
                <p>
                  As a graffiti writer turned visual artist, ZOOW24 (
                  <a href="https://twitter.com/zoow24">@zoow24</a>) has a
                  penchant for bringing vivid thoughts to reality. This master
                  of NEWOLDSCHOOL and graffiti 3.0 pioneer has been out in the
                  trenches with trailblazers of street art, graphic design, and
                  collectibles scenes for 10+ years.
                </p>
                <p>
                  From bombing trains with Trane + graffiti OGs like Shiche &
                  Shek, to headlining museum showcases in Germany, to
                  collaborative graphic design campaigns for brands like Moncler
                  & Balenciaga, and having custom clothing drops with OFF-White
                  & BadDeal, ZOOW24 is deeply entrenched in an exclusive,
                  gate-kept street-culture society.
                </p>
                <p>
                  He now, thanks to phygital NFTs, wants to give you a chance to
                  get involved in multiple layers of that culture, by immersing
                  yourself in the Drip-Lab experiment. Beyond his vast
                  professional accomplishments and street credibility, ZOOW is
                  an extremely passionate collector, always on the lookout for
                  vintage & rare clothing, sneakers, vinyl toys, memorabilia,
                  games, and art.
                </p>
              </div>
            </div>
          </Col>

          <Col md={6} className="mobile-only">
            <div className="behind__videoplayer">
              <ReactPlayer
                url="https://vimeo.com/843922624"
                controls={true}
                width="100%"
                height="850px"
              />
            </div>
          </Col>
        </Row>
        {/* mobile only */}
      </Container>
    </div>
  );
};
