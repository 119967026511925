import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Logo from "../../assets/media/images/logo.png";
// import Video from  '../../assets/media/videos/hero-video.mp4'
// import FooterVideo from '../../assets/media/videos/footer-video.mp4'
import WhitelistButton from '../../assets/media/images/whitelist-button.png'
/* import HeroVideo from  '../../assets/media/videos/video.mp4' */
/* import HeroVideo_mobile from  '../../assets/media/videos/dl-video-mob.mp4' */
import HeroVideo from  '../../assets/media/videos/ep2-long-small.mp4'
import HeroVideo_mobile from  '../../assets/media/videos/ep2-long-mob-small.mp4'


export const Hero = () => {
  
  return (
    <div className="hero pt-120 pb-60" id="story">
      <Container>
        <Row>
          <Col md={6} className="desktop-only">
            <div className="hero__videoplayer">
            <video autoplay="autoplay" muted loop playsinline width="100%">
               <source src={HeroVideo} type="video/mp4"/>
             </video>
            </div>
          </Col>
          <Col md={6} className="desktop-only">
            <div className="hero__content">
              <img src={Logo} alt="" width="100%" />
              <div className="hero__para">
                <p>
                Drip-lab is not an NFT project, it is a phygital tale of a street culture, summoned to life by 5 miniature behemoths that are meant to represent our lower selves, 
                birthed on the blockchain and spanning to our tactile reality.{" "}
                </p>
                <p>
                Join us on a serendipitous journey exploring what truly makes us human. Created by ZOOW24 in collaboration with Olmetex & Another-1.™
                </p>
                <button className="primary-btn mg-t-20"><a style={{color:'black',textDecoration:'none', fontSize:'1.4rem', fontWeight:'bold'}} target="_blank" href="https://launchpad.another-1.io/launchpad/drip-lab/mint">MINT{/* <img src={WhitelistButton} alt="" width="50px !important"/> */}</a></button>
              </div>
            </div>
          </Col>
          <Col md={6} className="mobile-only">
            <div className="hero__videoplayer">
             <video autoPlay={true} controls={false} muted loop={true} playsInline width="100%">
               <source src={HeroVideo_mobile} type="video/mp4"/>
             </video>
            </div>
          </Col>
          <Col md={6} className="mobile-only">
            <div className="hero__content">
              <div className="hero__para">
                <p>
                Drip-lab is not an NFT project, it is a phygital tale of a street culture, summoned to life by 5 miniature behemoths that are meant to represent our lower selves, 
                birthed on the blockchain and spanning to our tactile reality.{" "}
                </p>
                <p>
                Join us on a serendipitous journey exploring what truly makes us human. Created by ZOOW24 in collaboration with Olmetex & Another-1.™
                </p>
                <button className="primary-btn mg-t-20"><a style={{color:'black',textDecoration:'none', fontSize:'1.4rem', fontWeight:'bold'}} target="_blank" href="https://launchpad.another-1.io/launchpad/drip-lab/mint">MINT{/* <img src={WhitelistButton} alt="" width="50px !important"/> */}</a></button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
