import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import Hiw from "../../assets/media/images/hiw-2.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
/* import WallNight from "../../assets/media/images/wall-night.jpg";
import Video1 from  '../../assets/media/videos/video.mp4'
import Video2 from  '../../assets/media/videos/video.mp4'
import Video3 from  '../../assets/media/videos/video.mp4'
import Video4 from  '../../assets/media/videos/video.mp4'
import Video1_mobile from  '../../assets/media/videos/dl-video-mob.mp4'
import Video2_mobile from  '../../assets/media/videos/dl-video-mob.mp4'
import Video3_mobile from  '../../assets/media/videos/dl-video-mob.mp4'
import Video4_mobile from  '../../assets/media/videos/dl-video-mob.mp4'
import ReactPlayer from "react-player"; */
/* import JacketImg from "../../assets/media/images/artists-2.png"; */
import JacketImg from "../../assets/media/images/jacketImg.png";
import JacketVideo from "../../assets/media/videos/writersJacket.mp4"
import JacketVideoMobile from "../../assets/media/videos/writersJacket.mp4"

export const HowItWorks = () => {
  return (
    <div className="howItWorks pt-100 pb-20" id="phygital">
      <Container>



      <Row style={{paddingTop:'50px', paddingBottom:'70px', alignItems:"center"}}>
          <Col md={6} >
            <div className="monsterwall__text">
            <h1 className="mg-b-40" style={{color:'#AEFF0A', fontFamily:'Montserrat',fontStyle:'normal', fontWeight:'700',fontSize:'3rem',lineHeight:'78px',lineHeight:'100%',textAlign:'left'}}>The ‘Writer’s Jacket’</h1>
            <p>
              Unleash The Monster Within with The Drip-Lab Writer's Jacket!
              </p>
              <p>
              Experience fashion like never before with our exclusive shell jacket. Equipped with cutting-edge NFC technology, it offers unrivalled benefits to Drip-Lab NFT holders. 
              </p>
              <p>
              Designed by Olmetex, An Italian mill setting the standard for world-class technical fabrics, these jackets showcase five captivating thermoreactive dual-colourways and 
              feature ZOOW24’s monsters on the inside lining for a customized finish.
              </p>
              <p>
              But that's not all - NFC authentication ensures exclusivity, while token-gated access reveals provenance, and immersive content like the correlated PFP assets, 
              the 3D jacket model, exclusive media, and so much more. 
              </p>
              <p>
              In addition, thanks to the phygital NFC/NFT integration, the jackets will become compatible with Another-1’s upcoming Flex2Earn module which will enable users to 
              earn $AN1 tokens for authenticating & wearing the jacket.
              </p>
              <p>
              Redefine style and seize the opportunity to be rewarded for flexing while making a fashion statement and immersing yourself in graffiti culture, with or without 
              the pain-stained fingertips.
              </p>
            </div>
          </Col>
          <Col md={1} className="desktop-only"></Col>
          <Col md={5} className="desktop-only">
          {/* <div className="hero__videoplayer"> */}
          <div className="behind__videoplayer">
              <ReactPlayer
                url="https://vimeo.com/845305171"
                controls={true}
                width="100%"
                height="621px"
              />
            </div>

          {/*   <video autoplay="autoplay" muted loop playsinline width="100%">
               <source src={JacketVideo} type="video/mp4"/>
             </video> */}
            {/* </div> */}
           {/*  <div className="monsterwall__image ">
              <img src={JacketImg} alt="" width="100%" />
            </div> */}
          </Col>
          
          <Col md={6} className="mobile-only">

          <div className="behind__videoplayer" style={{paddingTop:"50px"}}>
              <ReactPlayer
                url="https://vimeo.com/845305171"
                controls={true}
                width="100%"
                height="621px"
              />
            </div>
         {/*  <div className="hero__videoplayer">
             <video autoPlay={true} controls={false} muted loop={true} playsInline width="100%">
               <source src={JacketVideoMobile} type="video/mp4"/>
             </video>
            </div> */}
            {/* <div className="monsterwall__image ">
              <img src={JacketImg} alt="" width="100%" />
            </div> */}
          </Col>
      </Row>

      </Container>
    </div>
  );
};
