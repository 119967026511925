import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";
import GraffitiHistory from '../../assets/media/images/graffiti-history.png'
import GraffitiHistory2 from '../../assets/media/images/graffiti-history2.png'

const Counter = ({end,text})=>{
    const [number,setNumber] = useState(null);
    const ref = useRef(0);

    const accumulator = end/100;

    const updateCounterState = () => {
        if(ref.current < end){
            const result = Math.ceil(ref.current + accumulator);

            if(result > end) return setNumber(end);

            setNumber(result);
            ref.current = result;
        }

        setTimeout(updateCounterState, 200)
    }

    useEffect(()=>{

        let isMounted = true;

        if(isMounted){
            updateCounterState();
        }

        return ()=> isMounted = false;
    },[end])



    return <div className="single__number">
    <h2>{number}</h2>
    <p>{text}</p>
    </div>
}

export const Video = () => {
   
  return (
    <div className="video pt-70 pb-20">
      <Container>
        <Row>
        <Col md={12}>
            <div className="monsterwall__video desktop-only mg-b-70">
              <ReactPlayer
                url="https://vimeo.com/843915088"
                controls={true}
                width="100%"
                height="750px"
              />
            </div>

            <div className="monsterwall__video mobile-only">
              <ReactPlayer
                url="https://vimeo.com/843915088"
                controls={true}
                width="100%"
                height="387px"
              />
            </div>
          </Col>
          {/* <Col md={12} className="desktop-only">
              <div className="video__image">
                  <img src={GraffitiHistory} alt="" />
              </div>
          </Col> */}
          {/* <Col md={12} className="mobile-only">
              <div className="video__image">
                  <img src={GraffitiHistory2} alt="" />
              </div>
          </Col> */}
          <Col md={12} className="desktop-only">
            <div className="video__numbers mg-t-80">
                {/* <Row> */}
                {/* <Col md={2}> */}
                    <Counter end={5555} text="PFPS"/>

                {/* </Col> */}

                {/* <Col md={2}> */}
                <Counter end={5} text="MONSTERS"/>
                    
                {/* </Col> */}

                {/* <Col md={2}> */}
                    
                {/* </Col> */}

                {/* <Col md={2}> */}
                <Counter end={1793} text="BACKGROUNDS"/>
                    
                {/* </Col> */}

                {/* <Col md={2}> */}
                <Counter end={183} text="MONSTER TRAITS"/>
                    
                {/* </Col> */}

                {/* <Col md={2}> */}
                <Counter end={66} text="OUTWEAR"/>
                    
                <Counter end={41} text="STREET TOYS"/>
                {/* </Col> */}
                {/* </Row> */}
                
            </div>
          </Col>
          

          <Col md={12} className="mobile-only">
            <div className="video__numbers mg-t-40">
                <Row>
                <Col md={2}>
                    <Counter end={5555} text="PFPS"/>

                </Col>

                <Col md={2}>
                <Counter end={5} text="MONSTERS"/>
                    
                </Col>

                <Col md={2}>
                <Counter end={1793} text="BACKGROUNDS"/>
                    
                </Col>

                <Col md={2}>
                <Counter end={183} text="MONSTER TRAITS"/>
                    
                </Col>

                <Col md={2}>
                <Counter end={66} text="OUTWEAR"/>
                    
                </Col>

                <Col md={2}>
                <Counter end={41} text="STREET TOYS"/>
                    
                </Col>
                </Row>
                
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};