import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import $ from "jquery";
import "./overflow.css"
import Web3 from "web3";
import Story from "../../assets/media/images/story.png";
import Artists from "../../assets/media/images/artists.png";
import Hiw from "../../assets/media/images/hiw.png";
import Masterpiece from "../../assets/media/images/Masterpiece.png";
import Roadmap from "../../assets/media/images/roadmap.png";
import Faq from "../../assets/media/images/faq.png";
import Metamask from "../../assets/media/images/Connect.png";
import Logo from "../../assets/media/images/logo.png";
import Modal from "@mui/material/Modal";
import WhitelistButton from '../../assets/media/images/whitelist-button.png'
import DiscordMobile from '../../assets/media/images/mobile_menu_social_icon-01.png'
import InstagramMobile from '../../assets/media/images/mobile_menu_social_icon-02.png'
import TwitterMobile from '../../assets/media/images/mobile_menu_social_icon-03.png'
import Discord from '../../assets/media/images/discord.png'
import Instagram from '../../assets/media/images/instagram.png'
import Twitter from '../../assets/media/images/twitter.png'

export const Header = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const showNavigationHandler = () => {
    if (open) {
      handleClose();
    } else if (!open) {
      handleOpen();

    }
    $(".navigation__toggle").toggleClass("rotate");
    $("body").toggleClass("hide_scroll")
  };

  const hideNavigationHandler = () => {
    handleClose();
    $(".navigation__toggle").removeClass("rotate");
    $("body").removeClass("hide_scroll")
  };

  const connectMetamaskHandler = async () => {
    handleClose();
    $(".navigation__toggle").removeClass("rotate");
    $("body").removeClass("hide_scroll")
    if (window.ethereum) {
      await window.ethereum.request({ method: "eth_requestAccounts" });
      window.web3 = new Web3(window.ethereum);
      return true;
    }
    return false;
  };

  return (
    
    <Container>
      <Row>
        <Col md={12}>
          <div className="header">

            <div className="logo desktop-only">
              <img src={Logo} alt="" />
            </div>

            <div className="logo mobile-only">
              <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center'}}>
            <img style={{justifyContent:'space-around', width:'100%', maxWidth:'221px'}} src={Logo} alt=""/>
              </div>
            </div>

            {/* <div style={{display:'flex'}} className="logo mobile-only mobile-1199">
              <img style={{maxWidth:'150px'}} src={Logo} alt="" />
            </div> */}

            {/* <div className="socials desktop-only">
                    <a style={{padding:'10px'}} href="https://discord.com/invite/Hr5JrtGw9t" target="_blank"><img src={Discord} alt=""  width="39px" height="39px"/></a>
                    <a style={{padding:'10px'}} href="https://www.instagram.com/another1_io/" target="_blank"><img src={Instagram} alt=""  width="39px" height="39px"/></a>
                    <a style={{padding:'10px'}} href="https://twitter.com/another1_io" target="_blank"><img src={Twitter} alt=""  width="39px" height="39px"/></a>
                    <p style={{color:'white', display:'inline-flex', padding:'10px'}}>FOLLOW US</p>
            </div> */}
            
            <button id="nav-scroll"
              className="navigation__toggle"
              onClick={showNavigationHandler}
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
            <div className="navigation desktop-only desktop-1199">
              <ul>
                <li onClick={hideNavigationHandler}>
                  <a href="#story"
                   style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Story
                    {/* <img src={Story} width="75px" alt="" /> */}
                  </a>
                </li>
                <li onClick={hideNavigationHandler}>
                  <a href="#monsters"
                  style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Monsters
                    {/* <img src={Artists} width="80px" alt="" /> */}
                  </a>
                </li>
                <li onClick={hideNavigationHandler}>
                  <a href="#phygital"
                  style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Phyigital Item
                    {/* <img src={Artists} width="80px" alt="" /> */}
                  </a>
                </li>
               
                <li onClick={hideNavigationHandler}>
                  <a href="#masterpiece"
                   style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Masterpiece
                    {/* <img src={Masterpiece} width="110px" alt="" /> */}
                  </a>
                </li>
                <li onClick={hideNavigationHandler}>
                  <a href="#artists"
                   style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Artist
                    {/* <img src={Artists} width="80px" alt="" /> */}
                  </a>
                </li>
               
                <li onClick={hideNavigationHandler}>
                  <a href="#roadmap"
                   style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    Roadmap
                    {/* <img src={Roadmap} width="80px" alt="" /> */}
                  </a>
                </li>
               {/*  <li onClick={hideNavigationHandler}>
                  <a href="#how-it-works">
                    <img src={Hiw} width="110px" alt="" />
                  </a>
                </li> */}
                <li onClick={hideNavigationHandler}>
                  <a href="#faq"
                   style={{fontFamily:'Montserrat, sans-serif', color:'#ADFF08',textDecoration:'none', textTransform:'capitalize', textShadow:'0px 4px 4px 0px rgba(0, 0, 0, 0.25)', textAlign:'center', fontSize:'1.25rem', fontWeight:'500',letterSpacing:'-0.6px'}}
                  >
                    FAQ
                    {/* <img src={Faq} alt="" /> */}
                    </a>
                    </li>
                {/* <li onClick={connectMetamaskHandler}>
                  <button className="primary-btn">
                    <img src={Metamask} alt="" />
                  </button>
                </li> */}
              </ul>
            </div>

            <Modal
              className="mobile-only mobile-1199"
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="navigation">
                <ul>
                  <li onClick={hideNavigationHandler}>
                    <a href="https://discord.com/invite/Hr5JrtGw9t"><img src={DiscordMobile} alt="" width="180px" /></a>
                  </li>
                  <li onClick={hideNavigationHandler}>
                    <a href="https://www.instagram.com/another1_io/"><img src={InstagramMobile} alt="" width="180px" /></a>
                  </li>
                  <li onClick={hideNavigationHandler}>
                    <a href="https://twitter.com/another1_io"><img src={TwitterMobile} alt="" width="180px" /></a>
                  </li>
                  {/* <li onClick={hideNavigationHandler}><a href="#faq"><img src={Faq} alt="" /></a></li> */}
                  <li onClick={hideNavigationHandler}>
                  <button className="primary-btn mg-t-20"><a style={{color:'black',textDecoration:'none', fontSize:'1.4rem', fontWeight:'bold'}} target="_blank" href="https://www.premint.xyz/drip-lab/">PRE-MINT{/* <img src={WhitelistButton} alt="" width="50px !important"/> */}</a></button>
                    {/* <button className="primary-btn mg-t-20"><a target="_blank" href="https://www.premint.xyz/drip-lab/"><img src={WhitelistButton} alt="" width="90px !important" /></a></button> */}
                  </li>
                </ul>
              </div>
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
