import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MasterPiece2 from "../../assets/media/images/masterpeice-2.png";
import Wall1 from "../../assets/media/images/wall-1.png";
import Wall1Green from "../../assets/media/images/wall1-green.png";
import Wall2Green from "../../assets/media/images/wall2_green.png";
import Wall2 from "../../assets/media/images/wall-2.png";
import MasterpieceImg from "../../assets/media/images/mst-img-1.png";
import WallArt1 from "../../assets/media/images/wall-art-1.jpg";
import WallArt1New from "../../assets/media/images/wall-art-1-new.jpg";
import WallArt2 from "../../assets/media/images/wall-art-2.jpg";
import $ from "jquery";
import Draggable from "draggable";

const pinpointData1 = [
  {
    top: 225,
    left: 58,
    title: "First Hit",
    content1: "First legal wall made",
    content2: "by Trane and Zoow",
  },
  {
    top: 272,
    left: 45,
    title: "My Way",
    content1: "A crew which Zoow is part of",
    content2: "",
  },
  {
    top: 265,
    left: 161,
    title: "The Future is Now",
    content1: "The name of the very first textile",
    content2: "project Zoow did with Olmetex",
  },
  {
    top: 373,
    left: 90,
    title: "War",
    content1: "Historical moment",
    content2: "",
  },
  {
    top: 168,
    left: 525,
    title: "ZZZ",
    content1: "Eternal feeling of triedness",
    content2: "",
  },
  {
    top: 256,
    left: 710,
    title: "156",
    content1: "Historical crew of NYC which",
    content2: "includes best writers",
  },
  {
    top: 186,
    left: 832,
    title: "Preaking",
    content1: "What is happening right now",
    content2: "",
  },
  {
    top: 215,
    left: 1088,
    title: "Don't Eat Your Life",
    content1: "A phrase referring to encouraging society not to do",
    content2: "irreparable deeds, which ultimately backfire",
  },
  // {
  //   top: 373,
  //   left: 1030,
  //   title: "Post New York",
  //   content1: "Class evolution",
  //   content2: "",
  // },
  {
    top: 192,
    left: 1247,
    title: "Zoow",
    content1: "Signature",
    content2: "",
  },
  {
    top: 210,
    left: 1382,
    title: "Love you all",
    content1: "A metaphor that alludes to the current",
    content2: "historical period, related to the water gun",
  },
  {
    top: 205,
    left: 1475,
    title: "To The Moon",
    content1: "Connection of traditional with web3",
    content2: "",
  },
  {
    top: 165,
    left: 1808,
    title: "2022",
    content1: "Execution year",
    content2: "",
  },
  {
    top: 382,
    left: 1835,
    title: "Destroyed",
    content1: "The artwork will be taken off and",
    content2: 'made in pieces "destroy to recreate"',
  },
  {
    top: 369,
    left: 2069,
    title: "Press",
    content1: "The first press that featured",
    content2: "Trane's work",
  },
  {
    top: 287,
    left: 2073,
    title: "Tpk",
    content1: "Most important Paris crew",
    content2: "",
  },
  {
    top: 375,
    left: 2240,
    left2: 2035,
    title: "1s 2k",
    content1: "One shot, two kill",
    content2: "Do it well at first time!",
  },
  {
    top: 182,
    left: 2211,
    left2: 1950,
    title: "School History",
    content1: "We are graffiti middle",
    content2: "school history",
  },
  {
    top: 265,
    left: 2323,
    left2: 2130,
    title: "King",
    content1: "Section dedicated to",
    content2: "Virgil Abloh",
  },
];

const pinpointData2 = [
  {
    top: 249,
    left: 12,
    title: "Background",
    content1: "The background simulates the galaxy starts and ",
    content2: ' powerfull steller explosion "Supernova" as a metaphor',
    content3: "for the explosion of unique lettering styles, ",
    content4: "from the wlid to straight",
  },
  {
    top: 433,
    left: 54,
    title: "Wall",
    content1: "The wall is partly destroyed by",
    content2: "galactic explosion",
  },
  {
    top: 344,
    left: 493,
    title: "Left Side",
    content1: 'Done by artist "SHEK", which ideated attached',
    content2: "letters with arrows on each end point.",
    content3: "There was a challenge to do it, and still preserve the",
    content4: "readability due to extreme wall dimensions and distance.",
  },
  {
    top: 439,
    left: 1121,
    title: "The Book",
    content1: "No rules - represent hte meaning of success. It points out",
    content2:
      "the freedom of choices, where each person should act by its own mind,",
    content3: "after questioning possible outcomes and arguments.",
    content4: "Never follow, create your own path.",
  },
  {
    top: 271,
    left: 1235,
    title: "The Owl",
    content1: 'Symbolizes "good luck" and it has been the mark of',
    content2: "Olmetex family since they've started their textile company.",
    content3: "The idea is to implement it across future projects,",
    content4: "as well as this particular one.",
  },
  {
    top: 322,
    left: 1728,
    title: "Right Side",
    content1: 'The right side of the artwork is done by artist "SHICHE"',
    content2: "and presents the perfect mix between NYC style,",
    content3: "U.V. from Paris, and DDS from London. In other",
    content4: "words, balance between simple and wild!",
  },
];

const Pinpoint = ({
  top,
  left,
  leftC2,
  title,
  content1,
  content2,
  content3,
  content4,
}) => {
  const [show, setShow] = useState(false);

  const showPinpointContentHandler = () => {
    setShow(true);
  };

  const hidePinpointContentHandler = () => {
    setShow(false);
  };

  const top2 = top + 15;
  const left2 = left + 22;
  return (
    <React.Fragment>
      <div
        className="circle"
        style={{ top: top + "px", left: left + "px" }}
        onMouseEnter={showPinpointContentHandler}
        onMouseLeave={hidePinpointContentHandler}
      >
        {" "}
      </div>
      {show && (
        <div
          className="circle__content"
          style={{ top: top2 + "px", left: leftC2 ? leftC2 : left2 + "px" }}
        >
          <h2>{title}</h2>
          <p>
            {content1}
            <br />
            {content2}
            <br />
            {content3}
            <br />
            {content4}
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export const Masterpiece = () => {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [wall1, setwall1] = useState(true);
  const [wall2, setwall2] = useState(false);

  const setImageHandler1 = () => {
    setActive1(true);
    setActive2(false);
    setwall1(true);
    setwall2(false);
  };
  const setImageHandler2 = () => {
    setActive2(true);
    setActive1(false);
    setwall1(false);
    setwall2(true);
  };

  return (
    <div className="masterpiece pt-110 pb-40" id="masterpiece">
      <Container>
        <Row>
          <Col md={12}>
            <div className="title mg-b-40">
              <img className="title-img" src={MasterPiece2} alt="" />
              <p>
                Hover over highlighted spots to learn more details. <br /> Slide
                to reveal hidden artwork sections.
              </p>
              <div className="">
                <button
                  className={active1 ? "primary-btn" : "primary-btn not-active"}
                  onClick={setImageHandler1}
                >
                  <img src={Wall1} alt="" />
                </button>
                <button
                  className={active2 ? "secondary-btn active" : "secondary-btn"}
                  onClick={setImageHandler2}
                >
                  <img src={Wall2} alt="" />
                </button>

                {wall1 && (
                  <div className="masterpiece__image mg-t-40">
                    <img src={WallArt1New} alt="" width="2440px" />

                    {pinpointData1?.map((el) => (
                      <Pinpoint
                        top={el.top}
                        left={el.left}
                        leftC2={el?.left2}
                        title={el.title}
                        content1={el.content1}
                        content2={el.content2}
                        content3={el?.content3}
                        content4={el?.content4}
                      />
                    ))}
                  </div>
                )}

                {wall2 && (
                  <div className="masterpiece__image mg-t-70">
                    <img src={WallArt2} alt="" width="2440px" />

                    {pinpointData2?.map((el) => (
                      <Pinpoint
                        top={el.top}
                        left={el.left}
                        leftC2={el?.left2}
                        title={el.title}
                        content1={el.content1}
                        content2={el.content2}
                        content3={el?.content3}
                        content4={el?.content4}
                      />
                    ))}
                  </div>
                )}
              </div>

              {/* <div className="mobile-only">
                <img src={Wall1Green} alt="" className="mobile-title-img"/>
                 <img src={Wall1} alt="" /> 
                  <div className="masterpiece__image mg-t-70">
                    <img src={WallArt1} alt="" width="2440px" />

                    {pinpointData1?.map((el) => (
                      <Pinpoint
                        top={el.top}
                        left={el.left}
                        leftC2={el?.left2}
                        title={el.title}
                        content1={el.content1}
                        content2={el.content2}
                        content3={el?.content3}
                        content4={el?.content4}
                      />
                    ))}
                  </div>

                  <img src={Wall2Green} alt="" className="mobile-title-img mg-t-50"/>
                  <div className="masterpiece__image mg-t-70">
                    <img src={WallArt2} alt="" width="2440px" />

                    {pinpointData2?.map((el) => (
                      <Pinpoint
                        top={el.top}
                        left={el.left}
                        leftC2={el?.left2}
                        title={el.title}
                        content1={el.content1}
                        content2={el.content2}
                        content3={el?.content3}
                        content4={el?.content4}
                      />
                    ))}
                  </div>
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
