import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Logo2 from '../../assets/media/images/AN1.png'
import Logo3 from '../../assets/media/images/Olmetex.png'
import Logo from '../../assets/media/images/logo.png'
import Discord from '../../assets/media/images/discord.png'
import Instagram from '../../assets/media/images/instagram.png'
import Twitter from '../../assets/media/images/twitter.png'


export const Footer = () => {
  return (
    <div className="footer pb-80">
        <Container>
            <div className="footer__container desktop-only">
            <Row>
                <Col md={4}>
                    <div className="footer__data">
                        <p>POWERED BY</p>
                        <a href="https://another-1.io/" target="_blank"><img src={Logo2} alt="" height="40px"/></a>
                  <a href="https://olmetex.it/it/" target="_blank"><img src={Logo3} alt="" height="40px" /></a>
                        {/* <p>&copy; Drip-Lab. All rights reserved.</p> */}
                    </div>
                </Col>

                <Col md={4}>
                    <div className="footer__data display-row">
                        <img src={Logo} alt="" width="221px"/>
                        <p>&copy; Drip-Lab. All rights reserved.</p>
                        </div>
                </Col>

                <Col md={4}>
                    <div className="footer__data social__links__footer">
                    <a href="https://discord.com/invite/Hr5JrtGw9t" target="_blank"><img src={Discord} alt=""  width="39px" height="39px"/></a>
                    <a href="https://www.instagram.com/another1_io/" target="_blank"><img src={Instagram} alt=""  width="39px" height="39px"/></a>
                    <a href="https://twitter.com/another1_io" target="_blank"><img src={Twitter} alt=""  width="39px" height="39px"/></a>
                        <p>FOLLOW US</p>
                    </div>
                </Col>
            </Row>
            </div>
            
            <div className="footer__container mobile-only">
            <Row>
            <Col md={4}>
                    <div className="footer__data display-row">
                        <img src={Logo} alt="" width="221px"/>
                        
                        </div>
                </Col>

                <Col md={4}>
                    <div className="footer__data social__links__footer">
                    <a href="https://discord.com/invite/Hr5JrtGw9t"><img src={Discord} alt=""  width="59px"/></a>
                        <a href="https://www.instagram.com/another1_io/"><img src={Instagram} alt=""  width="59px"/></a>
                        <a href="https://twitter.com/another1_io"><img src={Twitter} alt=""  width="59px"/></a>
                    </div>
                </Col>

                <Col md={4}>
                    <div className="footer__data">
                        <p>POWERED BY</p>
                        <a href="https://another-1.io/" target="_blank"><img src={Logo2} alt="" /></a>
                  <a href="https://olmetex.it/it/" target="_blank"><img src={Logo3} alt="" /></a>
                  <p>&copy; Drip-Lab. All rights reserved.</p>
                        {/* <p>&copy; Drip-Lab. All rights reserved.</p> */}
                    </div>
                </Col>

                

                
            </Row>
            </div>
        </Container>
    </div>
  )
}
