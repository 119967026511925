import "./App.css";
import "./assets/css/style.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./components/header/Header";
import { Hero } from "./components/hero/Hero";
import { Video } from "./components/video/Video";
import { BehindTheWalls } from "./components/behindTheWalls/BehindTheWalls";
import { Artists } from "./components/artists/Artists";
import { HowItWorks } from "./components/howItWorks/HowItWorks";
import { Masterpiece } from "./components/masterpiece/Masterpiece";
import { Roadmap } from "./components/roadmap/Roadmap";
import { Faq } from "./components/faq/Faq";
import { History } from "./components/history/History";
import { Newsteller } from "./components/newsteller/Newsteller";
import { Footer } from "./components/footer/Footer";
import { MonsterWall } from "./components/monsterWall/MonsterWall";
import { Countdown } from "./components/countdown/Countdown";
import ScrollToTop from "./components/scrollToTop";
import { CollectionDesc } from "./components/colDesc/CollectionDesc";

function App() {

  return (
    <div id='main-cont' className="app">
      <ScrollToTop />
      <Header />
      <Hero />
      {/* <Countdown/> */}
      <Video />
      <MonsterWall />

      {/* <CollectionDesc></CollectionDesc> */}
      <HowItWorks />
      <Masterpiece />
      <Artists />
      
      {/* <BehindTheWalls/> */}
      <Roadmap />
      
      {/* <Faq/> */}
      {/* <History/> */}
      <Newsteller />
      <Footer />
    </div>
  );
}

export default App;
