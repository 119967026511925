import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import JoinDiscord from "../../assets/media/images/join-the-discord-desk.png";
import JoinDiscordNew from "../../assets/media/images/join-the-discord-new.png";
import JoinDiscordMobile from "../../assets/media/images/join-the-discord-mobile.png";
import One from "../../assets/media/images/One.png";
import Two from "../../assets/media/images/Two.png";
import Three from "../../assets/media/images/Three.png";
import Four from "../../assets/media/images/Four.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export const Newsteller = () => {
  return (
    <div className="newsteller pt-70 pb-100" id="faq">
      <Container>
        <Row>
          <Col md={12} className="desktop-only">
            <div className="join__discord pt-50 pb-50">
              <img src={JoinDiscord} alt="" width="100%" className="mg-b-40" /> 

                <Row>
                  <Col md={3}>
                    <div className="single__join__step">
                      <img src={One} alt="" width="54px" />
                      <h3>How many NFTs are there?</h3>
                      <p>
                      The Drip-Lab genesis collection features 5555 NFTs, each with its own respective PFP character and redeemable shell jacket + other secret perks.
                      </p>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="single__join__step">
                      <img src={Two} alt="" width="54px" />
                      <h3>Which Blockchain are they on?</h3>
                      <p>
                      The Drip-Lab NFTs are minted on the Polygon blockchain - ERC-721 Non Fungible Token Standard.
                      </p>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="single__join__step">
                      <img src={Three} alt="" width="54px" />
                      <h3>Can I trade them after minting?</h3>
                      <p>
                      Yes, all Drip-Lab NFTs will be trade-able on secondary NFT markets such as OpenSea post minting. 
                      </p>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="single__join__step">
                      <img src={Four} alt="" width="54px" />
                      <h3>How do I get whitelisted?</h3>
                      <p>
                      In order to get whitelisted for the drop, make sure to join the Drip-Lab Premint list, join the Discord community, follow the news on Twitter, and stay ready.
                      </p>
                    </div>
                  </Col>
                </Row>
            </div>
          </Col>

          <Col md={12}>
            <div className="join__discord mobile-only">
              <img src={JoinDiscordNew} alt="" width="100%" className="mg-b-10" /> 

              <div className="owl__carousel mobile-only">
                <OwlCarousel className="owl-theme " loop margin={10} items={1}>
                  <div className="single__join__step__mob">
                    {/* <img src={One} alt="" width="54px" /> */}
                    <h3>How many NFTs are there?</h3>
                    <p>
                    The Drip-Lab genesis collection features 5555 NFTs, each with its own respective PFP character and redeemable shell jacket + other secret perks.
                    </p>
                  </div>

                  <div className="single__join__step__mob">
                    {/* <img src={Two} alt="" width="54px" /> */}
                    <h3>Which Blockchain are they on?</h3>
                    <p>
                    The Drip-Lab NFTs are minted on the Polygon blockchain - ERC-721 Non Fungible Token Standard.
                    </p>
                  </div>

                  <div className="single__join__step__mob">
                    {/* <img src={Three} alt="" width="54px" /> */}
                    <h3>Can I trade them after minting?</h3>
                    <p>
                    Yes, all Drip-Lab NFTs will be trade-able on secondary NFT markets such as OpenSea post minting.
                    </p>
                  </div>

                  <div className="single__join__step__mob">
                    {/* <img src={Four} alt="" width="54px" /> */}
                    <h3>How do I get whitelisted?</h3>
                    <p>
                    In order to get whitelisted for the drop, make sure to join the Drip-Lab Premint list, join the Discord community, follow the news on Twitter, and stay ready.
                    </p>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
