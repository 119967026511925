import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import MonsterwallImage from "../../assets/media/images/monsterwall-image.png";
import MonsterwallImage2 from "../../assets/media/images/monsterwall-image-2.png";
import MonsterRoot from "../../assets/media/images/root.png";
import MonsterCrymelon from "../../assets/media/images/crymelon.png";
import MonsterKult from "../../assets/media/images/kult.png";
import MonsterRekt from "../../assets/media/images/rekt.png";
import MonsterExto from "../../assets/media/images/exto.png";
import MonsterRootWalk from "../../assets/media/videos/monsters/root-walk-small.mp4";
import MonsterCrymelonWalk from "../../assets/media/videos/monsters/crymelon-walk-small.mp4";
import MonsterKultWalk from "../../assets/media/videos/monsters/kult-walk-small.mp4";
import MonsterRektWalk from "../../assets/media/videos/monsters/rekt-walk-small.mp4";
import MonsterExtoWalk from "../../assets/media/videos/monsters/exto-walk-small.mp4";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const data = [
  {
    id: "1",
    key: "1",
    title: "Root",
    subtitle: "The Clock",
    text: "Time waits for no man! Stewing and wallowing are the demise of many strong souls. Letting time slip through your fingers and your ambition goes with it is a waste of a life. Root is a reminder to all that life is precious and we easily waste away sitting and watching the clock. We live on borrowed time, and each moment is a chance to create or to make someone smile. Don’t procrastinate!",
    thumbnail: MonsterRoot,
    img: MonsterRootWalk,
  },
  {
    id: "2",
    key: "2",
    title: "Crymelon",
    subtitle: "The Spray Can",
    text: "Adrenaline is one helluva drug. The rush from tagging up a subway car, hearing sirens and seeing lights looming above as you make a dash for freedom wiping your paint stained fingertips and stuffing your supplies in your bag. Crymelon is the adrenaline junkie, the for-the-thrill-of-it tagger with no fear. As much as having no fear is great, one learns that as vices mature they push people beyond their own limits. Jail or the hospital kindly await.",
    thumbnail: MonsterCrymelon,
    img: MonsterCrymelonWalk,
  },
  {
    id: "3",
    key: "3",
    title: "Kult",
    subtitle: "The Pizza",
    text: "Gluttony is one of the seven deadly sins and Kult has indulged so much to the point he has become his desires, a gigantic melting pizza. Long lonely nights spent stress eating and shovelling Ben & Jerry's pints down the gullet has stopped many great leaders from achieving their goals. To control the gut is to control the mind. Kult has control of neither and prefers to chase his meals with wine.",
    thumbnail: MonsterKult,
    img: MonsterKultWalk,
  },
  {
    id: "4",
    key: "4",
    title: "Rekt",
    subtitle: "The Cigarette Pack",
    text: "In the words of Chief Keef - “Damn I hate being sober, I’m a smoker”, substance abuse can present itself in many ways and is usually a result of a lack of self-love. Smoking a pack, an ounce, or large amounts of mysterious substances per day can hurt you and the people around you. It can ruin relationships and your financial health. Moderation is key in life, and Rekt never learned this. Thus, his name.",
    thumbnail: MonsterRekt,
    img: MonsterRektWalk,
  },
  {
    id: "5",
    key: "5",
    title: "Exto",
    subtitle: "The Fire Extinguisher",
    text: "Don’t fall victim to lust... We’ve lost many great creatives to the throes of their sexual desires. There seems no end to satisfying one's sexual urges, and the more one welcomes the overindulgence into their life the more demoralizing the acts become. Exto is that one guy. The horny guy. The guy that can’t stop. Our sexual desires are beautiful but led to excess causing us to become like Exto, a perverse steam engine fixated on busting.",
    thumbnail: MonsterExto,
    img: MonsterExtoWalk,
  },
];

export const MonsterWall = () => {
  const [toggle, setToggle] = useState("3");
  return (
    <div
      className="monsterwall pb-20"
      id="monsters"
      style={{ paddingTop: "50px" }}
    >
      <Container>
        <Row>
          <Col md={5} className="desktop-only">
            <div className="monsterwall__image ">
              <img src={MonsterwallImage} alt="" width="100%" />
            </div>
          </Col>
          <Col md={1} className="desktop-only"></Col>
          <Col md={6} className="mobile-only">
            <div className="monsterwall__image ">
              <img src={MonsterwallImage2} alt="" width="100%" />
            </div>
          </Col>
          <Col md={6}>
            <div className="monsterwall__text ">
              <p>
                What’s life without death, sun without rain, a championship win
                without a celebratory drink? There is a balance of necessary
                evils every person must find in search of higher meaning. For
                some it may be drinking, for others it may be cigarettes. 
              </p>
              <p>
                Drip-Lab is a cautionary expose of what vices become when they
                spiral out of control. When you stop doing the drugs and the
                drugs do you. Habits are what make us human and how we choose to
                spend our time defines us as people.
              </p>
              <p>
                Becoming old guys in the graffiti scene we have seen many
                talented artists waste away consumed by their vices. It is in
                good health to acknowledge your flaws, to be aware of what evils
                you are up against, rather than to bury your bad habits like
                secrets you hide from the world. Every man has his vices, but
                learning to grasp them is one of life’s greatest challenges.
              </p>
              <p>
                Drip-Lab brings forth man’s greatest vices to create discussion,
                and a foundation to grow from. Clean out the skeletons in your
                closet and hang em on your wall! Matter-of-fact, hang em on your
                twitter profile. ;)
              </p>
              <p>
                Our five monsters weave an intrepid tale of lust, addiction,
                wastefulness, gluttony and inaction, the biggest mistakes we’ve
                seen talented men and women fall victim to. You wear one of
                these monsters not as a badge of dishonor but a gentle reminder
                that life is precious, short, and easily wasted away.
              </p>
            </div>
          </Col>

          <Row style={{ paddingTop: "50px" }}>
            <Col md={5}>
              {data.map(({ title, subtitle, text, key }) => {
                return (
                  <>
                    <div className="monsterwall__text ">
                      <div className="monsterwall__text ">
                        {toggle === key ? (
                          <>
                            <h1
                              style={{
                                color: "#AEFF0A",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "700",
                                fontSize: "3rem",
                                lineHeight: "78px",
                                lineHeight: "100%",
                                textAlign: "justify",
                                paddingTop: "50px",
                              }}
                            >
                              {title}
                            </h1>
                            <h2
                              style={{
                                color: "#FFFFFF",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "2rem",
                                lineHeight: "39px",
                                lineHeight: "100%",
                                textAlign: "left",
                              }}
                            >
                              {subtitle}
                            </h2>
                            <h2
                              style={{
                                color: "#FFFFFF",
                                fontFamily: "Montserrat",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "2rem",
                                lineHeight: "39px",
                                lineHeight: "100%",
                                textAlign: "left",
                              }}
                            >
                              Monster
                            </h2>

                            <p style={{ paddingTop: "30px" }}>{text}</p>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                );
              })}
            </Col>
            <Col md={1} className="desktop-only"></Col>
            <Col md={6}>
              {data.map(({ key, img }) => {
                return (
                  <>
                    <div className="monsterwall__image ">
                      {toggle === key ? (
                        <>
                          <video
                            autoPlay={true}
                            controls={false}
                            muted
                            loop={true}
                            playsInline
                            width="100%"
                          >
                            <source src={img} key={key} type="video/mp4" />
                          </video>
                        </>
                      ) : null}
                    </div>
                  </>
                );
              })}
            </Col>
          </Row>
          <div className="desktop-only">
            <Row>
              <Col md={1}></Col>
              <Col md={2}>
                <div
                  onClick={() => setToggle("1")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterRoot} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "28px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    ROOT
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div
                  className="single__monster__image"
                  onClick={() => setToggle("2")}
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterCrymelon} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "28px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    CRYMELON
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div
                  className="single__monster__image"
                  onClick={() => setToggle("3")}
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterKult} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "28px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    KULT
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div
                  className="single__monster__image"
                  onClick={() => setToggle("4")}
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterRekt} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "28px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    REKT
                  </p>
                </div>
              </Col>
              <Col md={2}>
                <div
                  className="single__monster__image"
                  onClick={() => setToggle("5")}
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterExto} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      fontSize: "28px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    {" "}
                    EXTO
                  </p>
                </div>
              </Col>
              <Col md={1}></Col>
            </Row>
          </div>

          <div className="mobile-only">
            <Row>
              <Col xs={1} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}></Col>
              <Col xs={2} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div
                  onClick={() => setToggle("1")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterRoot} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "20px",
                      fontSize: "10px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    ROOT
                  </p>
                </div>
              </Col>

              <Col xs={2} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div
                  onClick={() => setToggle("2")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterCrymelon} alt="" width="100%" />
                  </div>
                  
                </div>
                <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "20px",
                      fontSize: "10px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    CRYMELON
                  </p>
              </Col>
              <Col xs={2} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div
                  onClick={() => setToggle("3")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterKult} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "20px",
                      fontSize: "10px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    KULT
                  </p>
                </div>
              </Col>
              <Col xs={2} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div
                  onClick={() => setToggle("4")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterRekt} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "20px",
                      fontSize: "10px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    REKT
                  </p>
                </div>
              </Col>
              <Col xs={2} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                <div
                  onClick={() => setToggle("5")}
                  className="single__monster__image"
                >
                  <div className="single__monster__image__container">
                    <img src={MonsterExto} alt="" width="100%" />
                  </div>
                  <p
                    style={{
                      textAlign: "center",
                      fontFamily: "Montserrat, sans-serif",
                      marginTop: "20px",
                      fontSize: "10px",
                      color: "#adff08",
                      marginBottom: "2px",
                      fontWeight: "700",
                    }}
                  >
                    EXTO
                  </p>
                </div>
              </Col>
              <Col xs={1} style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}></Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};
