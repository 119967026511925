import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Roadmap2 from "../../assets/media/images/roadmap-2.png";
import Q1 from "../../assets/media/images/q1.png";
import Q2 from "../../assets/media/images/q2.png";
import Q3 from "../../assets/media/images/q3.png";
import Q4 from "../../assets/media/images/q4.png";
import Checked from "../../assets/media/images/checked.png";
import unChecked from "../../assets/media/images/unchecked.png";
import Loading from "../../assets/media/images/loading.png";
import Loaded from "../../assets/media/images/loaded.png";
import Loaded2 from "../../assets/media/images/loaded-2.png";
import Step1 from "../../assets/media/images/step-1-black.png";
import Step2 from "../../assets/media/images/step-2-black.png";

export const Roadmap = () => {
  const [active1, setActive1] = useState(true);
  const [active2, setActive2] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const [loading, setLoading] = useState(false);

  const setLoadHandler1 = () => {
    setActive1(true);
    setActive2(false);
    setLoaded(true);
    setLoading(false);
  };
  const setLoadHandler2 = () => {
    setActive2(true);
    setActive1(false);
    setLoaded(false);
    setLoading(true);
  };

  return (
    <div className="roadmap pt-100 pb-70" id="roadmap">
      <Container>
        <Row>
          <Col md={12}>
            <div className="title mg-b-80">
              <img src={Roadmap2} alt="" />
            </div>
          </Col>
          <div className="roadmap__content desktop-only">
            <Row>
              <Col md={6}>
                <div className="load loaded">
                  {/* <img src={Loaded} alt="" /> */}
                  <ul>
                    <li><img src={Checked} alt="" />PROJECT CONCEPTUALIZATION</li>
                    <li><img src={Checked} alt="" />ARTIST CURATION</li>
                    <li><img src={Checked} alt="" />ENGINEERING OF THE WALL</li>
                    <li><img src={Checked} alt="" />MURAL 1 by ZOOW24 & TRANE</li>
                    <li><img src={Checked} alt="" />MURAL 2 by ZOOW24 & SHECK</li>
                    <li><img src={Checked} alt="" />PHYSICAL EXHIBITION IN MILAN, IT</li>
                    <li><img src={Checked} alt="" />TOKENIZING THE WALL</li>
                    <li><img src={Checked} alt="" />NARRATIVE & STORYBOARDING</li>
                    <li><img src={Checked} alt="" />PFP COLLECTION DEVELOPMENT</li>
                    <li><img src={Checked} alt="" />ANIMATED SHORTS PRODUCTION</li>
                    <li><img src={Checked} alt="" />JACKET DESIGN & PROTOTYPING</li>
                  </ul>
                </div>
              </Col>

              <Col md={6}>
                <div className="load loading">
                  {/* <img src={Loading} alt="" /> */}
                  <ul>
                    <li><img src={Checked} alt="" />GO-TO MARKET STRATEGY</li>
                    <li><img src={Checked} alt="" />PREMINT CAMPAIGN</li>
                    <li><img src={Checked} alt="" />ZOOW24 X FAIR.XYZ OE MINT</li>
                    <li><img src={Checked} alt="" />KOLs & COLLAB MANAGERS</li>
                    <li><img src={unChecked} alt="" />MINT DAY</li>
                    <li><img src={unChecked} alt="" />SPECIAL AIRDROP</li>
                    <li><img src={unChecked} alt="" />FORGING & REDEMPTION</li>
                    <li><img src={unChecked} alt="" />SHIPPING & DELIVERY</li>
                    <li><img src={unChecked} alt="" />FLEX 2 EARN ACTIVATION</li>
                    <li><img src={unChecked} alt="" />NFT STAKING</li>
                    <li><img src={unChecked} alt="" />TBA</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>

          <div className="roadmap__content mobile-only">
            <div className="loadingMain">  <button
              className={active1 ? "primary-btn" : "primary-btn not-active"}
              onClick={setLoadHandler1}
            >
              <img src={Step1} alt="" />
            </button>
              <button
                className={active2 ? "secondary-btn active" : "secondary-btn"}
                onClick={setLoadHandler2}
              >
                <img src={Step2} alt="" />
              </button>
            </div>
            <Row>
              {loaded && <Col md={6}>
                <div className="load loaded">

                  <ul>
                  <li><img src={Checked} alt="" />PROJECT CONCEPTUALIZATION</li>
                    <li><img src={Checked} alt="" />ARTIST CURATION</li>
                    <li><img src={Checked} alt="" />ENGINEERING OF THE WALL</li>
                    <li><img src={Checked} alt="" />MURAL 1 by ZOOW24 & TRANE</li>
                    <li><img src={Checked} alt="" />MURAL 2 by ZOOW24 & SHECK</li>
                    <li><img src={Checked} alt="" />PHYSICAL EXHIBITION IN MILAN, IT</li>
                    <li><img src={Checked} alt="" />TOKENIZING THE WALL</li>
                    <li><img src={Checked} alt="" />NARRATIVE & STORYBOARDING</li>
                    <li><img src={Checked} alt="" />PFP COLLECTION DEVELOPMENT</li>
                    <li><img src={Checked} alt="" />ANIMATED SHORTS PRODUCTION</li>
                    <li><img src={Checked} alt="" />JACKET DESIGN & PROTOTYPING</li>
                  </ul>
                </div>
              </Col>}

              {loading && <Col md={6}>
                <div className="load loading">

                  <ul>
                  <li><img src={Checked} alt="" />GO-TO MARKET STRATEGY</li>
                    <li><img src={Checked} alt="" />PREMINT CAMPAIGN</li>
                    <li><img src={Checked} alt="" />ZOOW24 X FAIR.XYZ OE MINT</li>
                    <li><img src={Checked} alt="" />KOLs & COLLAB MANAGERS</li>
                    <li><img src={unChecked} alt="" />MINT DAY</li>
                    <li><img src={unChecked} alt="" />SPECIAL AIRDROP</li>
                    <li><img src={unChecked} alt="" />FORGING & REDEMPTION</li>
                    <li><img src={unChecked} alt="" />SHIPPING & DELIVERY</li>
                    <li><img src={unChecked} alt="" />FLEX 2 EARN ACTIVATION</li>
                    <li><img src={unChecked} alt="" />NFT STAKING</li>
                    <li><img src={unChecked} alt="" />TBA</li>
                  </ul>
                </div>
              </Col>}
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};
